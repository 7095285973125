.inline_composer {
  border: 0.05em solid #eee;
  margin-bottom: 1em;
  border-radius:0.25em;

}

.inline_composer .buttons {
  border-top: 0.05em solid #eee;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}
